// App.jsx
import React from 'react';
import Background from './components/Background/Background';
import styles from './App.module.scss';
import Questionaire from './components/Questionaire/Questionaire';

function App() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.backgroundAnimations}>
                <Background character="." seedRange={[0, 6]} />
                <Background character="." seedRange={[0, 6]} />
            </div>
            <Questionaire />
        </div>
    );
}

export default App;
