/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
// Import necessary libraries and modules
import React, { useState, useEffect } from 'react';
import styles from './WillYou.module.scss';

import Slideshow from '../Slideshow/Slideshow';

// Import images
import Panda2 from '../../assets/panda (2).gif';
import Panda3 from '../../assets/panda (3).gif';
import Panda4 from '../../assets/panda (4).gif';
import Panda5 from '../../assets/panda (5).gif';
import CatdancingGif from '../../assets/catdancing.gif';
import CatHeadGif from '../../assets/cathead.gif';

// Import sounds
import madSound from '../../assets/mad.mp3';
import sadSound from '../../assets/sad.mp3';
import celebration from '../../assets/celebration.mp3';
import backgroundKdrama from '../../assets/background-kdrama.mp3';
import catdancingSound from '../../assets/catdancing.mp3';
import catHeadSound from '../../assets/cathead.mp3';

function pauseAudio() {
    const ongoingAudio = document.querySelector('audio');
    if (ongoingAudio) {
        ongoingAudio.pause();
        ongoingAudio.currentTime = 0;
    }
}

function CatDancing() {
    const [shouldUnmount, setShouldUnmount] = useState(false);

    useEffect(() => {
        pauseAudio();

        const audio = new Audio(catdancingSound);
        audio.play().catch((error) => console.error('Audio playback error:', error));

        const duration = 6000;
        const timeoutId = setTimeout(() => {
            audio.pause();
            audio.currentTime = 0;
            setShouldUnmount(true);
        }, duration);

        return () => clearTimeout(timeoutId);
    }, []);

    return shouldUnmount ? null : <img src={CatdancingGif} alt="Cat dancing" className={styles.catDancing} />;
}

function CatHead() {
    const [shouldUnmount, setShouldUnmount] = useState(false);

    useEffect(() => {
        pauseAudio();

        const audio = new Audio(catHeadSound);
        audio.play().catch((error) => console.error('Audio playback error:', error));

        const duration = 5000;
        const timeoutId = setTimeout(() => {
            audio.pause();
            audio.currentTime = 0;

            setShouldUnmount(true);
        }, duration);

        return () => clearTimeout(timeoutId);
    }, []);

    return shouldUnmount ? null : <img src={CatHeadGif} alt="Cat head" className={styles.catDancing} />;
}

function WillYou({ setNewText }) {
    const [noPressCount, setNoPressCount] = useState(0);
    const [showYes, setShowYes] = useState(false);
    const [saidYes, setSaidYes] = useState(false);

    const ImageList = [Panda3, Panda4, Panda5];
    const randomImage = ImageList[Math.floor(Math.random() * ImageList.length)];

    const SoundList = [madSound, sadSound];
    const randomSound = SoundList[Math.floor(Math.random() * SoundList.length)];

    const handleNoPress = () => {
        if (noPressCount < 3) {
            // Continue prank
            setNoPressCount((prevCount) => prevCount + 1);

            // Apply prank effects
            // You can customize these styles as needed
            const button = document.querySelector(`.${styles.submitPass} button[type="button"]`);
            if (button) {
                button.style.transform = `scale(${1 + noPressCount * 0.4})`;

                // Calculate darker color with pointy edges
                const darkenFactor = noPressCount * 10;
                const redValue = Math.max(255 - darkenFactor, 0);
                button.style.backgroundColor = `rgb(${redValue}, 0, 178)`;

                // Apply pointy edges style
                button.style.borderRadius = noPressCount < 3 ? `${noPressCount * 10}px` : '0';
            }

            pauseAudio();

            const audio = new Audio(randomSound);
            audio.src = randomSound;
            audio.preload = 'auto';
            audio.play().catch((error) => console.error('Audio playback error:', error));
        } else {
            setShowYes(true);
            setNewText(true);
        }
    };

    const handleYesPress = () => {
        pauseAudio();

        const celebrationAudio = new Audio(celebration);
        celebrationAudio.play().catch((error) => console.error('Audio playback error:', error));

        const audio = new Audio(backgroundKdrama);
        audio.play().catch((error) => console.error('Audio playback error:', error));

        setSaidYes(true);
    };

    return (
        <div className={styles.submitPass}>
            {!saidYes && !noPressCount > 0 && <img src={Panda2} alt="Panda" className={styles.gif} />}
            {!saidYes && noPressCount > 0 && <img src={randomImage} alt="Panda" className={styles.gif} />}
            {!saidYes && (
                <div className={styles.buttonWrapper}>
                    <button type="button" onClick={handleYesPress}>
                        Yes
                    </button>
                    {showYes && (
                        <button type="button" onClick={handleYesPress}>
                            Yes
                        </button>
                    )}
                    {!showYes && (
                        <button type="button" onClick={handleNoPress}>
                            No
                        </button>
                    )}
                </div>
            )}
            <CatDancing />

            {noPressCount === 1 && <CatHead />}
            {saidYes && <Slideshow />}
        </div>
    );
}

export default WillYou;
