/* eslint-disable no-bitwise */
/* eslint-disable operator-linebreak */
/* eslint-disable no-multi-assign */
import React, { useEffect, useRef } from 'react';
import styles from './Confetti.module.scss';

const NUM_CONFETTI = 350;
const COLORS = [
    [85, 71, 106],
    [174, 61, 99],
    [219, 56, 83],
    [244, 92, 68],
    [248, 182, 70],
];
const PI_2 = 2 * Math.PI;

const range = (a, b) => (b - a) * Math.random() + a;

class ConfettiParticle {
    constructor(w, h, xpos) {
        this.style = COLORS[~~range(0, 5)];
        this.rgb = `rgba(${this.style[0]},${this.style[1]},${this.style[2]}`;
        this.r = ~~range(2, 6);
        this.r2 = 2 * this.r;
        this.replace(w, h, xpos);
    }

    replace(w, h, xpos) {
        this.opacity = 0;
        this.dop = 0.03 * range(1, 4);
        this.x = range(-this.r2, w - this.r2);
        this.y = range(-20, h - this.r2);
        this.xmax = w - this.r;
        this.ymax = h - this.r;
        this.vx = range(0, 2) + 8 * xpos - 5;
        this.vy = 0.7 * this.r + range(-1, 1);
    }

    draw(context) {
        this.x += this.vx;
        this.y += this.vy;
        this.opacity += this.dop;

        if (this.opacity > 1) {
            this.opacity = 1;
            this.dop *= -1;
        }

        if (this.opacity < 0 || this.y > this.ymax) {
            this.replace(window.w, window.h, window.xpos);
        }

        if (!(this.x > 0 < this.xmax)) {
            this.x = (this.x + this.xmax) % this.xmax;
        }

        context.beginPath();
        context.arc(~~this.x, ~~this.y, this.r, 0, PI_2, false);
        context.fillStyle = `${this.rgb},${this.opacity})`;
        context.fill();
    }
}

function Confetti() {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        let w = 0;
        let h = 0;
        let xpos = 0.5;

        const resizeWindow = () => {
            w = canvas.width = window.innerWidth;
            h = canvas.height = window.innerHeight;
        };

        window.addEventListener('resize', resizeWindow, false);
        window.onload = () => setTimeout(resizeWindow, 0);

        document.onmousemove = (e) => {
            xpos = e.pageX / w;
        };

        window.requestAnimationFrame =
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.oRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            ((callback) => window.setTimeout(callback, 1000 / 60));

        const confettiParticles = Array.from({ length: NUM_CONFETTI }, () => new ConfettiParticle(w, h, xpos));

        const step = () => {
            window.requestAnimationFrame(step);
            context.clearRect(0, 0, w, h);
            confettiParticles.forEach((particle) => particle.draw(context));
        };

        step();
    }, []);

    return (
        <div className={styles.confettiContainer} style={{ zIndex: 100 }}>
            <canvas ref={canvasRef} id="world" className={styles.wrapper} />
        </div>
    );
}

export default Confetti;
