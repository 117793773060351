/* eslint-disable no-nested-ternary */
// eslint-disable-next-line consistent-return
import React, { useState, useEffect } from 'react';
import Prompt from '../Prompt/Prompt';
import styles from './Questionaire.module.scss';

import WillYou from '../Numpad/WillYou';
import FullscreenModal from '../FullScreenModal/FullScreenModal';

import FirstImageGif from '../../assets/panda (1).gif';

function Questionaire() {
    const [questionaireStarted, setQuestionaireStarted] = useState(false);
    const [showValentineText, setShowValentineText] = useState(false);
    const [newText, setNewText] = useState(false);
    const [showFullscreenPrompt, setShowFullscreenPrompt] = useState(false);

    const openFullscreenModal = () => {
        const docElm = document.documentElement;

        if (docElm.requestFullscreen) {
            docElm
                .requestFullscreen()
                .then(() => {
                    setShowFullscreenPrompt(false);
                })
                .catch((error) => {
                    console.error('Error attempting to enable fullscreen:', error);
                });
        } else if (docElm.msRequestFullscreen) {
            docElm.msRequestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
        }
    };

    const handleFullscreenClick = () => {
        if (showFullscreenPrompt) {
            openFullscreenModal();
            setQuestionaireStarted(true);
        }
    };

    useEffect(() => {
        console.log('showFullscreenPrompt:', showFullscreenPrompt);
    }, [showFullscreenPrompt]);

    useEffect(() => {
        if (questionaireStarted) {
            // After 1.5 seconds, set setShowValentineText to true
            const timeoutId = setTimeout(() => {
                setShowValentineText(true);
            }, 1500);

            return () => clearTimeout(timeoutId);
        }
    }, [questionaireStarted]);

    return (
        <div className={styles.box}>
            {showFullscreenPrompt && (
                <FullscreenModal onYes={handleFullscreenClick} onNo={() => setShowFullscreenPrompt(false)} />
            )}
            <div className={styles.wrapper}>
                {!questionaireStarted ? (
                    <Prompt handleResponse={setShowFullscreenPrompt} />
                ) : (
                    <div className={styles.valWrapper}>
                        {!showValentineText && (
                            <>
                                <h1 className={styles.valentineText}>Loading...</h1>
                                <img src={FirstImageGif} alt="First Imagde" className={styles.firstImage} />
                            </>
                        )}
                        {showValentineText && !newText && (
                            <h1 className={styles.valentineText}>
                                Will You
                                <br />
                                Be My Valentine?
                            </h1>
                        )}
                        {newText && (
                            <h1 className={styles.valentineText}>
                                It&apos;s Not
                                <br />
                                An Option! &gt;:v
                            </h1>
                        )}

                        {showValentineText && <WillYou setNewText={setNewText} />}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Questionaire;
