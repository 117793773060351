import React from 'react';
import styles from './FullScreenModal.module.scss';

function FullScreenModal({ onYes, onNo }) {
    const handleYesClick = (e) => {
        e.preventDefault();
        onYes();
    };

    const handleNoClick = (e) => {
        e.preventDefault();
        onNo();
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modal}>
                <h1>Welcome to Fullscreen Experience</h1>
                <div className={styles.submitPass}>
                    <button type="button" onClick={handleYesClick}>
                        Yes
                    </button>
                    <button type="button" onClick={handleNoClick}>
                        No
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FullScreenModal;
