/* eslint-disable no-multi-assign */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
import React, { useEffect } from 'react';
import styles from './AnimatedHeart.module.scss';

function AnimatedHeart() {
    useEffect(() => {
        const c = document.getElementById('alx');
        const a = c.getContext('2d');

        const e = [];
        const h = [];
        const WIDTH = (c.width = window.innerWidth);
        const HEIGHT = (c.height = window.innerHeight);
        const v = 32 + 16 + 8;
        const R = Math.random;
        const C = Math.cos;
        const Y = 6.3;

        for (let i = 0; i < Y; i += 0.2) {
            h.push([
                WIDTH / 2 + 210 * Math.sin(i) ** 3,
                HEIGHT / 2 + 13 * -(15 * C(i) - 5 * C(2 * i) - 2 * C(3 * i) - C(4 * i)),
            ]);
        }

        for (let i = 0; i < Y; i += 0.4) {
            h.push([
                WIDTH / 2 + 150 * Math.sin(i) ** 3,
                HEIGHT / 2 + 9 * -(15 * C(i) - 5 * C(2 * i) - 2 * C(3 * i) - C(4 * i)),
            ]);
        }

        for (let i = 0; i < Y; i += 0.8) {
            h.push([
                WIDTH / 2 + 90 * Math.sin(i) ** 3,
                HEIGHT / 2 + 5 * -(15 * C(i) - 5 * C(2 * i) - 2 * C(3 * i) - C(4 * i)),
            ]);
        }

        for (let i = 0; i < v;) {
            const x = R() * WIDTH;
            const y = R() * HEIGHT;
            const H = 80 * (i / v) + Math.random() * 100;
            const S = 40 * R() + 60;
            const B = 60 * R() + 20;
            const f = [];
            for (let k = 0; k < v;) {
                f[k++] = {
                    x,
                    y,
                    X: 0,
                    Y: 0,
                    R: 1 - k / v + 1,
                    S: R() + 1,
                    q: ~~(R() * v),
                    D: 2 * (i % 2) - 1,
                    F: 0.2 * R() + 0.7,
                    f: `hsla(${~~H},${~~S}%,${~~B}%,.1)`,
                };
            }
            e[i++] = f;
        }

        function path(d) {
            a.fillStyle = d.f;
            a.beginPath();
            a.arc(d.x, d.y, d.R, 0, Y, 1);
            a.closePath();
            a.fill();
        }

        const intervalId = setInterval(() => {
            a.fillStyle = 'rgba(248, 61, 210, .2)';
            a.fillRect(0, 0, WIDTH, HEIGHT);
            for (let i = v; i--;) {
                const f = e[i];
                const u = f[0];
                const q = h[u.q];
                const D = u.x - q[0];
                const E = u.y - q[1];
                const G = Math.sqrt(D * D + E * E);
                if (G < 10) {
                    if (R() > 0.95) {
                        u.q = ~~(R() * v);
                    } else {
                        if (R() > 0.99) u.D *= -1;
                        u.q += u.D;
                        u.q %= v;
                        if (u.q < 0) u.q += v;
                    }
                }
                u.X += (-D / G) * u.S;
                u.Y += (-E / G) * u.S;
                u.x += u.X;
                u.y += u.Y;
                path(u);
                u.X *= u.F;
                u.Y *= u.F;
                for (let k = 0; k < v - 1;) {
                    const T = f[k];
                    const N = f[++k];
                    N.x -= 0.7 * (N.x - T.x);
                    N.y -= 0.7 * (N.y - T.y);
                    path(N);
                }
            }
        }, 25);

        return () => clearInterval(intervalId);
    }, []);

    return <canvas id="alx" className={styles.background} />;
}

export default AnimatedHeart;
