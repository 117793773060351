/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable default-param-last */
import React, { useRef, useEffect } from 'react';

const palette = ['#FF66B2', '#FF3385', '#D81B60', '#C2185B', '#880E4F'];
const MAX_CELLS = 10;

function Background({ character = '\\', seedRange = (0, 2) }) {
    console.log('character: ', character);
    const canvasRef = useRef(null);
    const colonyRef = useRef({
        position: [0, 0],
        bound: 0,
        cells: [],
    });

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const resize = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            colonyRef.current.position[0] = canvas.width / 2;
            colonyRef.current.position[1] = canvas.height / 2;

            colonyRef.current.bound = window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight;
            colonyRef.current.bound /= 2;
        };

        const random = (min = 0, max) => Math.floor(Math.random() * (max - min + 1) + min);

        const distance = (pos1, pos2) => Math.sqrt((pos1[0] - pos2[0]) ** 2 + (pos1[1] - pos2[1]) ** 2);

        const createCell = (position, fever) => ({
            position,
            fever,
            fontSize: 10,
            targetFontSize: 30,
            color: 0,
            target: [...position],
        });

        const generateWorld = () => {
            for (let i = 0; i < MAX_CELLS; i++) {
                const cell = createCell(
                    [
                        colonyRef.current.position[0] + random(-200, 200),
                        colonyRef.current.position[1] + random(-200, 200),
                    ],
                    character,
                );
                colonyRef.current.cells.push(cell);
            }
        };

        const draw = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for (const cell of colonyRef.current.cells) {
                ctx.font = `${cell.fontSize}px Arial`;
                ctx.fillStyle = cell.color;
                ctx.fillText(cell.fever, cell.position[0], cell.position[1]);
            }
        };

        const update = () => {
            for (const cell of colonyRef.current.cells) {
                if (distance(cell.position, cell.target) < 1) {
                    setTarget(cell);
                }
                cell.position[0] += (cell.target[0] - cell.position[0]) * 0.01;
                cell.position[1] += (cell.target[1] - cell.position[1]) * 0.05;
                cell.fontSize += (cell.targetFontSize - cell.fontSize) * 0.01;
            }
        };

        const setTarget = (cell) => {
            cell.target[0] = colonyRef.current.position[0] + random(-colonyRef.current.bound, colonyRef.current.bound);
            cell.target[1] = colonyRef.current.position[1] + random(-colonyRef.current.bound, colonyRef.current.bound);
            const targetSeed = random(seedRange[0], seedRange[1]);
            cell.fontSize = cell.targetFontSize;
            cell.targetFontSize = 20 + 20 * targetSeed * targetSeed;
            cell.color = palette[random(0, 4)];
            cell.fever = character;
        };

        const handleResize = () => {
            resize();
        };

        const loop = () => {
            requestAnimationFrame(loop);
            update();
            draw();
        };

        // Event listeners
        window.addEventListener('resize', handleResize);

        // Initial setup
        resize();
        generateWorld();
        loop();
    }, []);

    return (
        <div>
            <canvas ref={canvasRef} />
        </div>
    );
}

export default Background;
