/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';

import dedications from './dedications.json';
import styles from './Slideshow.module.scss';
import AnimatedHeart from '../AnimatedHeart/AnimatedHeart';

import Slideshow1 from '../../assets/slideshow (1).JPG';
import Slideshow2 from '../../assets/slideshow (2).JPG';
import Slideshow3 from '../../assets/slideshow (3).JPG';
import Slideshow4 from '../../assets/slideshow (4).jpg';
import Slideshow5 from '../../assets/slideshow (5).JPG';
import Slideshow6 from '../../assets/slideshow (6).JPG';
import Slideshow7 from '../../assets/slideshow (7).JPG';
import Slideshow8 from '../../assets/slideshow (8).JPG';
import Slideshow9 from '../../assets/slideshow (9).jpg';
import Slideshow10 from '../../assets/slideshow (10).jpg';
import Slideshow11 from '../../assets/slideshow (11).jpg';
import Slideshow12 from '../../assets/slideshow (12).jpg';
import Slideshow13 from '../../assets/slideshow (13).jpg';
import Slideshow14 from '../../assets/slideshow (14).JPG';
import Slideshow15 from '../../assets/slideshow (15).JPG';
import Slideshow16 from '../../assets/slideshow (16).JPG';
import Slideshow17 from '../../assets/slideshow (17).jpg';
import Slideshow18 from '../../assets/slideshow (18).jpg';
import Confetti from '../Confetti/Confetti';

const SlideshowImages = [
    Slideshow1,
    Slideshow2,
    Slideshow3,
    Slideshow4,
    Slideshow5,
    Slideshow6,
    Slideshow7,
    Slideshow8,
    Slideshow9,
    Slideshow10,
    Slideshow11,
    Slideshow12,
    Slideshow13,
    Slideshow14,
    Slideshow15,
    Slideshow16,
    Slideshow17,
    Slideshow18,
];

function Slideshow() {
    const [finished, setFinished] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [previousImageIndex, setPreviousImageIndex] = useState(null);

    useEffect(() => {
        if (currentImageIndex < SlideshowImages.length - 1) {
            const timer = setTimeout(() => {
                setPreviousImageIndex(currentImageIndex);
                setCurrentImageIndex((prevIndex) => prevIndex + 1);
            }, 6500);

            return () => clearTimeout(timer);
        }
        if (currentImageIndex === SlideshowImages.length - 1) {
            const timer = setTimeout(() => {
                setFinished(true);
            }, 7500);

            return () => clearTimeout(timer);
        }
    }, [currentImageIndex]);

    if (finished) {
        return (
            <div className={styles.endWrapper}>
                <AnimatedHeart />
                <div className={styles.textWrapper}>
                    <img
                        src="https://media4.giphy.com/media/J4mc8iYbLNTtnoX8Uo/giphy.gif?cid=82a1493b6nwq3030b8z4476tc6qsv89zu82lelq1had8wkub&ep=v1_gifs_search&rid=giphy.gif&ct=s"
                        alt="Dancing Cat"
                        width="200"
                        height="200"
                    />
                    <h1>
                        ¡Orgulloso de que seas
                        <br />
                        mi valentin!
                    </h1>
                    <h2> Cuzo & Cuza</h2>
                    <img src="https://media.tenor.com/yk8nhx3fZGYAAAAj/sebas-bleizeffer.gif" alt="Sebas Bleizeffer" />
                    <Confetti />
                </div>
            </div>
        );
    }

    return (
        <div className={`${styles.slideshow} ${finished ? styles.hide : ''}`}>
            {previousImageIndex !== null && (
                <img
                    key={previousImageIndex}
                    src={SlideshowImages[previousImageIndex]}
                    alt={`Slideshow ${previousImageIndex}`}
                    className={`${styles.imageWrapper} ${styles.fadeOut}`}
                />
            )}
            <img
                key={currentImageIndex}
                src={SlideshowImages[currentImageIndex]}
                alt={`Slideshow ${currentImageIndex}`}
                className={`${styles.imageWrapper} ${styles.fadeIn}`}
            />
            {dedications.find((dedication) => dedication.id === currentImageIndex + 1) && (
                <div className={`${styles.message} ${styles.fadeIn}`}>
                    {dedications.find((dedication) => dedication.id === currentImageIndex + 1).message.includes('\n')
                        ? dedications
                              .find((dedication) => dedication.id === currentImageIndex + 1)
                              .message.split('\n')
                              .map((line, index) => (
                                  <React.Fragment key={index}>
                                      {line}
                                      <br />
                                  </React.Fragment>
                              ))
                        : dedications.find((dedication) => dedication.id === currentImageIndex + 1).message}
                </div>
            )}
        </div>
    );
}

export default Slideshow;
